import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';
import ReactGa from 'react-ga';

const PlayStore = (props) => { 
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.vacamacomp.temporadapp';
    const iosUrl = 'https://apps.apple.com/es/app/temporadapp-fruta-y-verdura/id1612303547';

    function handleClick(e) {
        ReactGa.event({
            category: 'Button',
            action: 'Click the Play Store Button',
            label: 'Play Store Button'
        })
    }

    return (
        <> 
        <div className="hide-on-med-and-down show-on-large">
            <MobileStoreButton
              className="play-button"
              store="android"
              url={androidUrl}
              linkStyles={{height: "4.4vmax", width: "12vmax"}}
              linkProps={{ title: 'Play Store Button', rel: "nofollow noreferrer noopener noindex",samesite:"Lax" }}
              style={{width: "12vmax", height: "4.4hmax", display: "inline-block", marginTop: "0.7vh", paddingLeft: "0.2vmax"}}
              onClick={handleClick}
            />
            <div style={{height: "3px"}}></div>
            <MobileStoreButton
              className="play-button"
              store="ios"
              url={iosUrl}
              linkStyles={{height: "4vmax", width: "10vmax"}}
              linkProps={{ title: 'Play Store Button', rel: "nofollow noreferrer noopener noindex",samesite:"Lax" }}
              style={{width: "10vmax", height: "4vmax", display: "inline-block", marginTop: "0.7vh"}}
              onClick={handleClick}
            />
        </div>
        <div className="show-on-medium-and-down hide-on-large-only" style={{height: "100px"}}>
            <MobileStoreButton
              className="play-button"
              store="android"
              url={androidUrl}
              linkStyles={{height: "57px", width: "150px"}}
              linkProps={{ title: 'Play Store Button', rel: "nofollow noreferrer noopener noindex",samesite:"Lax" }}
              style={{width: "150px", height: "57px", display: "inline-block", marginTop: "5px", paddingLeft: "0.2vmax"}}
              onClick={handleClick}
            />
            <div style={{height: "1px"}}></div>
            
            <MobileStoreButton
              className="play-button"
              store="ios"
              url={iosUrl}
              linkStyles={{height: "40px", width: "130px"}}
              linkProps={{ title: 'Play Store Button', rel: "nofollow noreferrer noopener noindex",samesite:"Lax" }}
              style={{width: "130px", height: "40px", display: "inline-block", marginTop: "0px"}}
              onClick={handleClick}
            />
        </div>  
        </>
    );
}

export default PlayStore;