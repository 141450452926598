/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { LOCALES } from '../i18nProvider/constants';


const en_MetaData = {
  'title': 'IG Tracker | The App to track Instagram Profiles 📱😍✅',
  'description':'🥇 An app to analyze Instagram Profiles and Download Publications and Profile Photos without authentication.',
  'url':'https:/temporadapp.com/',
  'highLightImage':'https://temporadapp.com/static/HighlightDisplay.webp'
};

const es_MetaData = {
  'title': 'TemporadApp | La App de las frutas y verduras de temporada 🍎🥦🍌',
  'description':'✅ Una app para mejorar hábitos saludables y el consumo mes por mes de fruta y verdura de temporada.',
  'url':'https:/temporadapp.com/',
  'highLightImage':'https://temporadapp.com/static/HighlightDisplay.webp'
};

const rich_snippet = {
                       "@context": "https://schema.org",
                       "@type": "MobileApplication",
                       "name": "TemporadApp",
                       "operatingSystem": "Android and iOS",
                       "applicationCategory": "HealthApplication",
                       "aggregateRating": {
                         "@type": "AggregateRating",
                         "ratingValue": "5",
                         "ratingCount": "4"
                       },
                       "offers": {
                         "@type": "Offer",
                         "price": "0",
                         "priceCurrency": "EUR",
                         "seller": {
                          "@type": "Organization",
                          "name": "Vacama Company"
                          }
                       }
                     }



function SEO(props) {
  var metaData;
  if(props.locale === LOCALES.SPANISH) {
    metaData = es_MetaData;
  }
  else {
    metaData = en_MetaData;
  }

  return (
    <Helmet>
      <title>{metaData['title']}</title>
      <htmlAttributes lang={props.locale} />
      <link rel="alternate" hreflang="es"
        href="http://temporadapp.com" />
      <link rel="icon" type="image/x-icon"
        href="http://temporadapp.com/static/icon-transparent.ico" />
      <meta name="robots" content="index,follow" />
      <meta name="og:title" content={metaData['title']} />
      <meta name="description" content={metaData['description']} />
      <meta name="og:description" content={metaData['description']} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metaData['url']} />
      <meta property="og:site_name" content={metaData['title']} />
      <meta property="og:image" content="https://temporadapp.com/static/icon-blank.webp"/>
      <meta property="og:image:secure_url" content="https://temporadapp.com/static/icon-blank.webp"/>
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:title" content={metaData['title']}/>
      <meta property="twitter:description" content={metaData['description']}/>
      <meta property="twitter:image" content={metaData['highLightImage']}/>
      <meta property="lang" content={props.locale}/>
      <meta name="theme-color" content="#0d4416"/>
      <script className='structured-data-list' type="application/ld+json">{JSON.stringify(rich_snippet)}</script>
    </Helmet>
  );
}

export default SEO
