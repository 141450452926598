import React from 'react';
import App from '../components/App';
import Seo from '../components/Seo'
import { LOCALES } from '../i18nProvider/constants';


const IndexPage = () => (
  
    <div>
      <Seo locale={LOCALES.SPANISH}/>
      <App locale={LOCALES.SPANISH}/>
    </div>
)


export default IndexPage