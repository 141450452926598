import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const esImgPaths = [
    <StaticImage className="materialboxed" src="../images/capturas-es/Display1.webp" alt="" />,
    <StaticImage className="materialboxed" src="../images/capturas-es/Display2.webp" alt="" />,
    <StaticImage className="materialboxed" src="../images/capturas-es/Display3.webp" alt="" />,
    <StaticImage className="materialboxed" src="../images/capturas-es/Display4.webp" alt="" />,
]


export default {
    'es': esImgPaths,
}