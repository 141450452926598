export default {
    /*FUNCIONALIDADES*/
    'presentation-title': 'Productos de Temporada',
    'presentation-desc': 'Consume fruta y verdura de temporada con ayuda de nuestra app',
    'anonymous-title': 'Sé anónimo',
    'anonymous-desc': 'No se pide ni email ni contraseña, tú solamente descárgate la app y empieza a usarla. Nadie sabrá quién eres ni a quien monitorizas',
    'track-title': 'Detalle del alimento',
    'track-desc': 'Infórmate de los valores nutricionales del alimento fácilmente',
    'display-content-title': 'Lista de Frutas y Verduras',
    'display-content-desc': 'Descubre qué alimentos de temporada hay en el mes que tú quieras y ahorra en tu compra',
    'notify-title': 'Monitoriza Perfiles Sin Esfuerzo',
    'notify-desc': 'Recibe notificaciones cuando se produzca un Cambio grande de Seguidores o Seguidos o cuando un usuario de Instagram suba un Post o IGTV',
    'download-title': 'Comparador de Verduras y Frutas',
    'download-desc': 'Intenta buscar qué alimentos son los mejores para aumentar el consumo de ciertos nutrientes',

    /*TABLA PROMOCIONAL*/
    'find-profiles': 'Encuentra Perfiles',
    'available-android': 'Disponible en Android',
    'notify-changes': 'Notifica Cambios',

    /*CONTACTO - FOOTER*/
    'vacama-product': 'Un producto de VACAMA COMPANY',
    'contact': 'Contacto',
    'follow-us': 'Síguenos!',
    'privacy-policy': 'Política de privacidad'
};

